/* Third party stylesheets */
@import "~bootstrap/scss/bootstrap.scss";
@import '~react-date-range/dist/styles.css'; 
@import '~react-date-range/dist/theme/default.css'; 

/* custom styles */
@import 'variables';
@import 'layout';
@import 'header';
@import 'ui';
@import 'quotation';
@import 'checkout';
@import 'footer';
@import 'common';


















