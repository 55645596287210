.insurance-type-step {
    @media #{$xs-layout}{
        position: relative;
    }
    .info {
        display: flex;
        .user-img {
            width: 70px;
            height: 70px;
            border-radius: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .greeting {
            margin-left: 16px;
            p,
            span {
                font-size: 20px;
                font-weight: bold;
                color: $grey90;
                @media #{$extra-small-mobile} {
                    font-size: 12px;
                }
            }
            p {
                color: $aquamarine;
                @media #{$xs-layout} {
                    color: $grey90;
                }
            }
        }
    }
    .insurance-card-wrapper {
        display: flex;
        justify-content: space-evenly;
        column-gap: 10px;
        @media #{$xl-layout} {
            width: 100%;        }
        @media #{$xs-layout}, #{$md-layout} {
            display: block;
        }
        @media #{$lg-layout} {
            width: 100%;      
          }
    }
}
.MuiFormHelperText-root {
    text-align: inherit !important;
}
.step{
    .MuiFormHelperText-root {
        text-align: inherit;
    }
    .head-wrapper {
        display: flex;
        justify-content: center;
        @media #{$xs-layout} {
            display: block;
        }
    }
    .info {
        display: flex;
        margin-left: 10px;
        @media #{$xs-layout} {
            margin-left: 0;
        }
        p {
            font-size: 14px;
            color: $grey90;
            font-weight: 400;
            margin-left: 8px;
        }
        .user-img {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.response-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    @media #{$xs-layout} {
        padding: 0;
    }
    
    .btn-wrapper{
        margin:  0;
        position: static;
        width: 60%;
      
        @media #{$small-mobile} {
                width: 80%;
              }
    }
    p {
        font-size: 22px;
        font-weight: bold;
        color: $grey90;
        text-align: center;
        margin: 30px ;
        @media #{$small-mobile} {
          font-size: 18px;
          margin:30px  0px;
        }
        @media #{$sm-layout} {
          margin:30px  0px;
        }
        span{
          @media #{$small-mobile} {
            display: block;
          }
        }
        
    }
    a {
        width: 100%;
    }
    button {
        height: 60px;
        background-color: $bg-blue;
        border-radius: 12px;
        border: none;
        width: 100%;
        color: $white;
        font-size: 22px;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 40px;
        opacity: 1;
        @media #{$small-mobile} {
                  font-size: 18px;
                }
    }
    .lose-discount {
      text-align: center;
      a {
                color: $grey90;
                font-size: 20px;
                font-weight: bold;
                text-decoration: none;
                @media #{$small-mobile} {
                  font-size: 17px;
                }
              }
    }
}
.purchase-wrapper {
    p {
        letter-spacing: 0.3px;
        padding: 0px 80px;
        @media #{$xs-layout} {
            padding: 0px;
        }
    }
    .text {
        margin-bottom: 50px;
        @media #{$xs-layout} {
            margin-bottom: 63px;
        }
    }
    .text-failed{
        margin-bottom: 67px;
        @media #{$xs-layout} {
            margin-bottom: 111px;
        }
    }
    .heading {
        text-align: center;
        margin-bottom: 36px;
        font-size: 25px;
        font-weight: bold;
        line-height: 32px;
    }
    .success {
        color: $aquamarine;
    }
    .failed {
        color: $red;
    }
    .btn-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0px 8px;
        margin-top: 0px;
    }
}
.failed-date-of-birth {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f44336;
}
[dir='rtl'] .failed-date-of-birth {
    float: right;
}
[dir='rtl'] .MuiAutocomplete-endAdornment {
    left: 0;
    right: inherit;
}
[dir='rtl'] .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    right: 0;
    font-family: 'Almarai';
    opacity: 0.2;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    display: none;
}
[dir='ltr'] .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Circular';
    opacity: 0.2;
}
[dir='ltr'] .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root{
    display: none;
}
[dir='rtl'] .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root{
    display: none;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    display: none;
}
[dir='rtl'] .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    margin-left: -14px;
}
.css-1dozdou {
    direction: initial;
}

.css-viou3o-MuiAutocomplete-root{
display: contents;
}

[dir='rtl'] .MuiSelect-icon {
    left: 0;
    right: inherit;
}
[dir='rtl'] .info .greeting {
    margin-left: 0px !important;
    margin-right: 16px !important;
}
[dir='rtl'] .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot{
    padding: 9px;
    }
[dir='rtl'] .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot , .MuiAutocomplete-hasPopupIcon.css-1l6di18 .MuiAutocomplete-inputRoot{
    padding-right: 0 !important;
    }
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot{
    padding-left: 0 !important;
    }
[dir='rtl'] .css-mqt2s5 {
        right: 0 !important;
        font-family: 'Almarai';
        opacity: 0.2;
 }
[dir='rtl'] .css-1nvf7g0 {
        margin-left: -8px !important ;
    }
.css-mqt2s5.Mui-focused, .css-348fk2.Mui-focused{
        display: none !important;
    }
[dir='rtl'].css-348fk2{
    display: none !important;
}
[dir='ltr'].css-348fk2{
    display: none !important;
}
.rdrCalendarWrapper {
    width: 100% !important;
}
.rdrMonth{
    width: 100% !important;
}
[dir='rtl']  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-right-radius: 1.042em !important;
    border-bottom-right-radius: 1.042em !important;
    right: 2px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    left: 0 !important;
}
[dir='rtl']  .rdrDayStartOfWeek .rdrInRange {
    border-top-right-radius: 1.042em !important;
    border-bottom-right-radius: 1.042em !important;
    right: 2px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    left: 0 !important;
}
[dir='rtl']  .rdrDayStartOfMonth .rdrInRange  {
    border-top-right-radius: 1.042em !important;
    border-bottom-right-radius: 1.042em !important;
    right: 2px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    left: 0 !important;
}
[dir='ltr']  .rdrDayStartOfMonth .rdrInRange {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    right: 0px !important;
    border-top-left-radius: 1.042em !important;
    border-bottom-left-radius: 1.042em !important;
    left: 2px !important;
}
[dir='ltr']  .rdrDayStartOfWeek  .rdrInRange {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    right: 0px !important;
}

[dir='rtl']  .rdrDayEndOfMonth .rdrInRange  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    right: 0px !important;
    border-top-left-radius: 1.042em !important;
    border-bottom-left-radius: 1.042em !important;
    left: 2px !important;
}
[dir='rtl']  .rdrDayEndOfWeek .rdrInRange , .rdrDayEndOfWeek .rdrStartEdge  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    right: 0px !important;
    border-top-left-radius: 1.042em !important;
    border-bottom-left-radius: 1.042em !important;
    left: 2px !important;
}
[dir='ltr']  .rdrDayEndOfWeek , .rdrDayEndOfWeek .rdrStartEdge  {
    border-top-right-radius: 1.042em !important;
    border-bottom-right-radius: 1.042em !important;
    right: 2px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    left: 0 !important;
}
[dir='rtl']  .rdrSelected, .rdrStartEdge{
    border-top-right-radius: 1.042em !important;
    border-bottom-right-radius: 1.042em !important;
    right: 2px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    left: 0 !important;
}
[dir='ltr'] .rdrStartEdge{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    right: 0px !important;
    border-top-left-radius: 1.042em !important;
    border-bottom-left-radius: 1.042em !important;
    left: 2px !important;
}
.date-range{
    text-align: center !important;
    margin-bottom: 50px !important;
}
[dir='rtl']  .rdrPprevButton i{
    margin: 0 0 0 7px !important;
    border-width: 4px 4px 4px 6px  !important;
    border-color: transparent transparent transparent rgb(52, 73, 94)!important;
    transform: translate(-3px, 0px) !important;
}
[dir='rtl']  .rdrNextButton i{
    border-width: 4px 6px 4px 4px !important;
    border-color: transparent rgb(52, 73, 94) transparent transparent !important;
    transform: translate(-10px, 0px) !important;
}
[dir='rtl']  .rdrEndEdge{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    right: 0px !important;
    border-top-left-radius: 1.042em !important;
    border-bottom-left-radius: 1.042em !important;
    left: 2px !important;
}
[dir='rtl']  .rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-right: 0.833em !important;
    margin-left: 0 !important;
}
[dir='rtl']  .rdrDayEndPreview {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    border-left-width: 1px !important;
    border-top-left-radius: 1.333em !important;
    border-bottom-left-radius: 1.333em !important;
}
[dir='rtl']  .rdrDayStartPreview {
    border-top-right-radius: 1.333em !important;
    border-bottom-right-radius: 1.333em !important;
    border-left: 0px !important;
    border-right-width: 1px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
[dir='rtl']  .rdrDayEndOfWeek .rdrDayInPreview {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
}
[dir='rtl']  .rdrDayStartOfWeek .rdrDayInPreview {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
}
[dir='ltr']  .rdrDayStartOfWeek  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
}
[dir='ltr']  .rdrDayEndOfWeek .rdrInRange  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
}
[dir='rtl']  .rdrDayEndOfWeek .rdrInRange  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    border-top-left-radius: 1.042em !important;
    border-bottom-left-radius: 1.042em !important;
    left: 2px !important;
}
.child-address{
    display: inline-block;
    @media #{$xs-layout}{
        display: block;
    }
}
.parent-address-container{
    display: flex;
    justify-content: space-between;
    @media #{$xs-layout}{
        display: block;
    }
}
.popup{
    display: flex;
    flex-direction: column;
    @media #{$xs-layout} {
        padding: 0;
    }
    p{
        font-size: 16px;
        font-weight: 500;
        white-space: pre-wrap;
    }
    button {
        height: 52px;
        background-color: $bg-blue;
        border-radius: 12px;
        border: none;
        color: $white;
        font-size: 20px;
        font-weight: 700;
    }
}
.popupCartona {
    direction: rtl;
    font-family: 'Almarai';
    .modal-header{
        border: none;
        padding-bottom: 0;
    }
    .modal-dialog{
        max-width: 440px;
        }
        h4{
        margin-bottom: 15px;
        }
        button{
            margin: 10px auto;
            padding-left: 40px;
            padding-right: 40px;
        }
}
.footer-app{
    @media #{$small-mobile} {
        height: 50px;
      }
}
[dir='rtl']  .helpIcon  {
    transform: scaleX(-1)
}
.css-1qqsdnr-MuiAutocomplete-root .MuiInput-root .MuiInput-input{
    border: none;
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
    background-color: $white !important;
}
[dir='rtl'] .MuiAutocomplete-hasPopupIcon.css-1lambp5 .MuiOutlinedInput-root{
    padding-right: 9px !important;
}
[dir='rtl'] .MuiAutocomplete-hasPopupIcon.css-1lambp5 .MuiAutocomplete-inputRoot{
    padding-right: 9px !important;
}
.partner-lead{
    flex-direction: row;
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @media #{$md-layout} {
        flex-direction: column;
        display: flex;
        align-items: flex-start;  
        gap: 0px;
      }
    @media #{$xs-layout} {
        flex-direction: column;
        display: flex;
        align-items: flex-start;  
        gap: 0px;
      }
}
.partner-lead-buttons{ 
    flex-direction: row;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
    @media #{$md-layout} {
        align-items: flex-start;  
        gap: 0px;
      }
    @media #{$xs-layout} {
        align-items: flex-start;  
        gap: 0px;
      }
      .btnDiv{
        min-width: 150px;
    }
}
.partner-lead-container{
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    width: -webkit-fill-available;
    min-height: 140px;
    @media #{$md-layout} {
        height: auto;
      }
    @media #{$xs-layout} {
        height: auto;
      }
}
.partner{
    display: flex;
    justify-content: center;
    .partner-title{
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 25px;
    background-color: $white;
    border-width: 40px;
    min-width: 360px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 4px 0 rgba(0, 0, 0, 0.19);
    border: $grey;
    border-style: solid;
    font-weight: 700;
    @media #{$xs-layout} {
        min-width: auto;
    }
}
}
.date-inputs{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    p{
        color: #373334;
        font-size: 20px;
        font-weight: bold;
    }
    .input-number{
        width: 60px;
        input{
            text-align: center;
        }
    }
    .input-year{
        width: 100px;
    }
    p{
        margin: 0;
    }
}
.request-call-card{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 20px;
    gap: 5px;
        p{
            text-align: center;
            margin: 0;
        }
        .btn-call{
            background-color: $bg-blue;
            color: $white;
            padding: 10px 20px;
            border-radius: 10px;
            border: none;
        }
}
.flex-row{
    display: flex;
    flex-direction: row;
    gap: 18px;
}
.whatsapp-icon{
    width: 20px;
    margin-left: 0px;
    margin-right: 5px;
}
[dir='rtl']  .whatsapp-icon {
    margin-right: 0px;
    margin-left: 5px;
}
.padding-for-phone{
    @media #{$xs-layout} {
        padding-top: 0 !important;
    }
}
.display-flex{
     color:$blue !important;
     @media #{$xs-layout} {
        display: flex;
        align-items: center;
        margin-right: 5px;
        margin-left: 5px;
        @media #{$xs-layout} {
           white-space: nowrap;
        }
        @media #{$small-mobile} {
           white-space: inherit;
        }
        
    }
    span{
        @media #{$xx-layout,$md-layout,$lg-layout,$xl-layout} {
            margin: 0 !important;
        }
    }
}
.medical-network{
    .header-layout{
        display: flex;
        justify-content: space-evenly;
        .title{
            img{
                cursor: pointer;
            }
            h3{
                font-size: 18px;
                color:#8692A6;
                text-align: center;
                @media #{$xs-layout,$md-layout}{
                    font-size: 16px;
                }
            } 
            p{
                font-size: 18px;
                color :#BDBDBD;
                @media #{$xs-layout,$md-layout} {
                    font-size: 16px;
                }
            }
        }
    }
    .partners .item p {
        color: #989898;
        margin: 0;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        max-width: 85%;
        @media #{$xs-layout,$md-layout} {
            font-size: 10px;
        }
    }
    .partners .item {
        cursor: pointer;
        padding: 16px 20px;
        display: flex;
        flex: 0 1 calc(50% - 1rem);
        gap: 15px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 0 0 48px #00000016;        
        margin-bottom: 20px;
        align-items: center;
        @media #{$xs-layout} {
        flex: 0 1 calc(100% - 1rem);
        padding: 10px 15px;
        }
        .title{
            font-weight: 700;
            color: black;
            font-size: 16px;
            @media #{$xs-layout,$md-layout} {
                font-size: 14px;
            }
            @media #{$extra-small-mobile} {
                font-size: 10px;
            }
         }
    }
    .partners .item img {
        background-color: #D8D8D8;
        width: 70px;
        height: 70px;
        border-radius: 10px;
        @media #{$xs-layout}{
            width: 60px;
            height: 60px;
        }
    }
    .filterNetworkSelect{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        @media #{$xs-layout} {
            flex-direction: column;
        }
    }
    .btn{
      
        display: flex;
        align-items: center;
        justify-content: center;
        button{
            width: fit-content;
            border: solid 1px #7d7a7a;
            background-color: white;
            border-radius: 14px;
            padding: 5px 10px;
            color: #7d7a7a;
        }
    }
    span{
        color: #0f3eca;
    }
    .noResults{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 100px;
        }
        p{
            font-size: 22px;
            font-weight: 700;
        }
    }
    .filter-medical-network{
        position: relative;
        span{
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: #68DBD3;
            top: -2px;
            right: -2px;
        }
    }
    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
        border-radius: 5px !important;
        ::placeholder {
            color: #989898;
            font-size: 14px;
            font-weight: bold;
            @media #{$xs-layout} {
                font-size: 12px;
            }
        }
    }
    .MuiInputBase-input {
        padding: 10px 0px 10px 10px !important;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus{
        border-bottom: 0px !important;
    }
}
.filter{
    .provider-options{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        .provider-option{
        border: 1px solid #DEDEDE;
        width: 30%;
        text-align: center;
        border-radius: 15px;
        color: #989898;
        padding: 6px;
        cursor: pointer;
        @media #{$small-mobile} {
            font-size: 10px;
        }
        }
        .active-provider{
            border: 1px solid #68DBD6;
          }
      }
     
      .select-all{
        width: 15px;
        height: 15px;
        border: 1px solid #DEDEDE;
        margin-top: 1rem;
        margin-right: 10px;
        &.active{
            background-color: #68DBD6;

        }
      }
      .search-btn-parent{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .search-btn{
            width: 50%;
            height: 50px;
            background-color: black;
            color: white;
            border-radius: 10px;
            font-weight: 700;
        }
      }
      .ant-drawer .ant-drawer-title {
        text-align: center !important;
      }
      .main-layout{
        .MuiInputBase-input {
           padding: 0px !important;
           border: none !important;
           height: 28px !important;
        }
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
            border-radius: 5px !important;
            ::placeholder {
                color: #989898;
                font-size: 14px;
                font-weight: bold;
                @media #{$xs-layout} {
                    font-size: 12px;
                }
            }
        }
      }
}
[dir='rtl'] .select-all{
    margin-right: 0px;
    margin-left: 10px;
}

.ant-drawer .ant-drawer-title {
    text-align: center !important;
  }
.reset{
    margin-bottom: 0px;
    color: #68DBD6;
    font-size: 16px;
    cursor: pointer;
}
.provider-details{
    .header{
        h3{
           text-align: center;
           font-size: 22px;
           font-weight: bold;
           color: #1F2937;
           padding: 2rem 0px;
           @media #{$xs-layout,$md-layout}{
            font-size: 16px;
        }
        }
 
    }
    .card-layout{
        border-radius: 10px;
        box-shadow: 0 0 48px #00000016;
        margin-bottom: 30px;
        .divider{
            border-bottom: 1px solid #DADADA;
        }
        .info{
            display: flex;
            padding: 20px 25px;
            gap:10px;
            align-items: center;
            @media #{$xs-layout}{
                padding: 10px 15px;
            }
        
            img{
                background-color: #D8D8D8;
                width: 70px;
                height: 70px;
                border-radius: 10px;
                @media #{$xs-layout}{
                    width: 60px;
                    height: 60px;
                }
            }
            span{
                color: #989898;
                margin: 0;
                font-size: small;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                white-space: nowrap;

            }
            h5{
                font-weight: bold;
                color: black;
                font-size: 16px;
            @media #{$xs-layout,$md-layout} {
                font-size: 14px;
            }
            @media #{$extra-small-mobile} {
                font-size: 10px;
            }
            }
        }
        .address{
            padding: 20px 25px;
            display: flex;
            gap:2%;
            align-items: center;
            @media #{$xs-layout}{
                padding: 10px 15px;
            }
            p{
                flex-basis: 59%;
                color: #989898;
                font-weight: bold;
                font-size: 14px;
                margin: 0;
                @media #{$xs-layout,$md-layout} {
                    font-size: 10px;
                }
            }
            button{
                flex-basis: 39%;
                border: 1px solid black;
                background-color: white;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                font-weight: bold;
                color: black;
                font-size: 16px;
                white-space: nowrap;
                @media #{$xs-layout,$md-layout} {
                    font-size: 12px;
                    padding: 5px 8px;
                }
                @media #{$extra-small-mobile} {
                    font-size: 10px;
                }
                img{
                    margin-right: 8px;
                }
                [dir='rtl'] .img{
                    margin-right: 0px;
                    margin-left: 8px;
                }
            }
        }
         .contacts{
            padding: 20px 25px;
            @media #{$xs-layout}{
                padding: 10px 15px;
            }
            p{
                font-weight: bold;
                font-size: 16px;
                color: black;
                @media #{$xs-layout,$md-layout} {
                    font-size: 10px;
                }
            }
            .numbers-wrapper{
                display: flex;
                gap: 2%;
                flex-wrap: wrap;
                @media #{$xs-layout} {
                    gap: 1%;
                  }  
                .number-card{
                   border:1px solid #C4C4C4;
                   padding: 5px 30px;
                   border-radius: 25px;
                   margin-bottom: 6px;
                   display:flex;
                   @media #{$xs-layout} {
                    padding: 2px 8px;
                  }  
                   span{
                    font-weight: bold;
                    text-align: center;
                    color:#989898;
                    font-size: 14px;
                    @media #{$xs-layout} {
                        font-size: 12px;
                      }  
                   }
                }
            }
    
        }
    }
}
.medical-network-container{
    width: 65%;
    margin: 0px auto;
    background-color: #FFFFFF;
    @media #{$xs-layout} {
       width: 90%;
     }

}