.checkout{
    padding-top: 30px;
    @media #{$xs-layout} {
        padding-top: 0px;
    }
    .payment-wrapper{
        h3{
            font-size: 16px;
            color: $grey90;
            margin-bottom: 12px;
            line-height: 24px;
        }
        .cards-wrapper{
            display: inline-flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 24px;
        }
    }

}
.kiosk-code-wrapper{
    @media #{$xs-layout}{
        padding-top: 112px;
    }
    p{
        color: $black;
        letter-spacing: 0.3px;
    }
    .heading{
        font-size: 40px;
        color: $blue;
        line-height: 52px;
        font-weight: 700;
    }
    .how-to-pay{
        padding: 20px;
        background-color: $bg-grey10;
        border-radius: 12px;
        
        @media #{$xs-layout}{
            margin: 0px;
        }
    }
}