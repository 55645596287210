footer {
    background-color: #231F20;
    padding-top: 90px;
    .empty-space{
      min-height: 16px;
    }
  .payment-logos{
  margin-top: 16px;
  align-items: center;
  .col-6{
    display: table-cell;
    vertical-align: middle;
    img{
      max-width: 100px;
    }
  }
  .logo-col{
    img{
    max-width: 95px;
    }
  }
  }
    .section-content {
      margin: auto;
      width: 90%;
      @media #{$md-layout}{
        width: 100%;
      }

  
      .social-media-icon-container {
        padding: 8px 10px;
        background-color: white;
        border-radius: 50%;
        margin: 8px;
  
        .social-media-icon {
          width: 16px;
          height: 14px;
          padding-bottom: 2px;
        }
      }
  
      .align-end {
        text-align: end;
      }
  
      .align-center {
        text-align: center;
      }
  
      .company-col {
        img {
          margin-bottom: 12px;
        }
  
        .escalate-link {
          color: #F7E610;
          text-decoration: none;
        }
      }
  
      .footer-col {
        margin-bottom: 32px;
      }
  
      .head {
        font-family: Circular;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        margin-bottom: 22px;
      }
  
      .menu-item, .menu-item a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #B8B2B3;
        text-decoration:none;
        font-family: 'Circular';
      }
    }
  }
  
  @media (max-width:768px) {
    .center-min {
      text-align: center !important;
    }
  }
  
  @media (min-width: 320px) and (max-width: 1140px) {
    .section {
      padding-left: 1em;
      padding-right: 1em;
    }
  }