
// text Colors ---------------

$off-white: #f1f2f2;
$black: #231f20;
$aquamarine: #71dbd4;
$blue: #0f3eca;
$yellow:#f7e610;
$green: #b1d519;
$primary:$aquamarine;
$secondary:#a2a2a2;
$grey:#B8B2B3;
$grey90:#373334;
$grey-light:#D5D6D8;
$grey80:#656162;
$white:#fff;
$red:#D40000;
$grey10:#F8F8F8;


// bg Colors ---------------

$bg-off_white: #f1f2f2;
$bg-black:#231f20;
$bg_aquamarine:#71dbd4;
$bg-blue: #0f3eca;
$bg-yellow: #f7e610;
$bg-green: #b1d519;
$bg-grey:#f5f5f5;
$bg-white:#ffffff;
$bg-grey1:#CFCFCF;
$bg-aqua:#26F7FD;
$bg-grey10:#F8F8F8;
$bg-dark-blue:#00279C;
$bg-red:#D40000;


// Responsive Variables 
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
$small-mobile: 'only screen and (max-width: 575px)';
$extra-small-mobile: 'only screen and (min-width: 280px)and (max-width: 360px)';
// $extra-small-mobile: 'only screen and (max-width: 479px)';
$desktop-layout:'only screen and (min-width: 992px)'








