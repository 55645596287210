@font-face {
    font-family: Almarai;
    src: local('Almarai-Regular'),
        url(../fonts/Almarai-Regular.ttf) format('TrueType');
    font-display: swap;
}
@font-face {
    font-family: Circular;
    src: local('Circular-Std-Book'),
        url(../fonts/Circular-Std-Book.ttf) format('TrueType');
    font-display: swap;    
}
body {
    font-family: 'Circular', sans-serif;
}
[dir='rtl'] body {
    font-family: 'Almarai', sans-serif;
}
.lottie-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

#page-wrapper {
    position: relative;
    padding-top: 42px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    min-height: calc(100vh - 92px);

    @media #{$xs-layout},#{$md-layout} {
        padding-top: 30px;
        min-height: calc(100vh - 180px);
    }
}
#root {
    height: 100vh;
}
.main-layout {
    background-color: red;
    background-image: url('../../assets/img/main_background.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    min-height: 100%;
    @media #{$xs-layout},#{$md-layout},#{$lg-layout} {
        background: none;
    }
}
.wrapper {
    width: 66%;
    margin: 0 auto;

    @media #{$xs-layout}, #{$sm-layout}, #{$small-mobile}, #{$extra-small-mobile},#{$md-layout} {
        width: 100%;
        margin: 0;
    }
    @media #{$lg-layout} {
        width: 80%;
        margin: 0 auto;
    }
}
.home-wrapper {
    width: 85%;
    margin: 0 auto;

    @media #{$xs-layout}, #{$sm-layout}, #{$small-mobile}, #{$extra-small-mobile},#{$md-layout} {
        width: 100%;
    }
    @media #{$xx-layout} {
        width: 80%;
    }
    @media #{$lg-layout} {
        width: 95%;
    }
}
.checkout-wrapper {
    width: 95%;
    margin: 0 auto;

    @media #{$xs-layout}, #{$sm-layout}, #{$small-mobile}, #{$extra-small-mobile},#{$md-layout} {
        width: 100%;
        margin: 0;
    }
}
.mongez {
    .modal-dialog {
        direction: rtl;
    }
}
.modalPopup {
    .modal-dialog {
        max-width: 335px;
        margin: auto;
    }
    .modal-content{
        border-radius: 12px;
    }
    .modal-header{
        border: none;
        padding-bottom: 0;
    }
    .modal-body {
        padding-top: 0px;
    }
}
[dir='rtl'] .modal-header .btn-close{
    margin: 0px;
}

.btn-wrapper {
    width: 46%;
    margin: 60px auto 0px auto;
    @media #{$md-layout}{
        width: 50%;
    }
    @media #{$lg-layout}{
        width: 40%;
    }
    @media #{$xs-layout}, #{$sm-layout}, #{$small-mobile}, #{$extra-small-mobile} {
        width: 100%;
        margin: 60px 0;
        position: static;
    }
}
.float-above{
    position: static;
    width: 46%;
    @media #{$xs-layout}, #{$sm-layout}, #{$small-mobile}, #{$extra-small-mobile} {
        width: 100%;
        margin: 20px 0px;
    }
}
.static{
    position: static;
    width: 46%;
    @media #{$xs-layout}{
        width: 100%;
    }
}
input {
    font-size: 16px;
    color: $black;
    &::placeholder {
        color: $grey-light;
        font-size: 16px;
    }
    &:focus {
        border-bottom: 1px solid $black;
    }
}
.MuiInputBase-root {
    color: $black !important;
    font-family: 16px !important;
    line-height: 24px !important;
    font-family: 'Circular' !important;
   
    input {
        font-size: 16px;
        color: $black;
        &::placeholder {
            color: $grey-light;
            font-size: 16px;
        }
    }
}
[dir='rtl'] .MuiInputBase-root {
    font-family: 'Almarai' !important;
    text-align: right !important;
   
}
[dir='rtl'] .MuiInputBase-root input{
    text-align: right !important;
}
.body-14 {
    font-size: 14px;
    line-height: 20px;
}
.body-16 {
    font-size: 16px;
    line-height: 24px;
}
.body-20 {
    font-size: 20px;
    line-height: 28px;
}
.bold {
    font-weight: 700;
}
.subtitle {
    font-weight: 700;
    line-height: 20px;
}
.gap-120 {
    gap: 120px;
    @media #{$md-layout} {
        gap:30px;
    }
}
.flex-box {
    display: flex;
    @media #{$xs-layout} {
        display: block;
    }
}
.flex-basis-50 {
    flex-basis: 50%;
}
.flex-basis-35 {
    flex-basis: 35%;
}
.iframe {
    background-color: transparent;
}
.iframeOut {
    margin: 0px !important;
}
.react-tabs {
    ul {
        display: flex;
        justify-content: center;
        margin-bottom: 0px;
        .react-tabs__tab {
            list-style: none;
            font-size: 20px;
            line-height: 28px;
            color: $black;
            font-weight: 400;
            font-family: 'Circular';
            margin-right: 20px;
            cursor: pointer;
            padding-top: 4px;
            &:last-child {
                margin: 0px;
            }
        }
        .react-tabs__tab--selected {
            padding: 4px 20px;
            background-color: $bg-blue;
            color: $grey10;
            border-radius: 30px;
        }
    }
}

[dir='rtl'] .react-tabs ul .react-tabs__tab {
    font-family: 'Almarai';
    margin-left: 20px;
    margin-right: 0px;
    &:last-child {
        margin: 0px;
    }
}
.makeStyles-placeholder-1{
    color: $grey-light !important;
    font-size: 16px;
}
/*************************
  Basic margin padding
*************************/

.m-0 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.p-0 {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

/*************************
         Margin top
*************************/

.mt-0 {
    margin-top: 0;
}

.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}
.mt-55 {
    margin-top: 55px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-110 {
    margin-top: 110px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-130 {
    margin-top: 130px;
}

.mt-140 {
    margin-top: 140px;
}

.mt-150 {
    margin-top: 150px;
}

/*************************
      Margin right
*************************/

.mr-0 {
    margin-right: 0px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-35 {
    margin-right: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-60 {
    margin-right: 60px;
}

.mr-70 {
    margin-right: 70px;
}

.mr-80 {
    margin-right: 80px;
}

.mr-90 {
    margin-right: 90px;
}

.mr-100 {
    margin-right: 100px;
}

.mr-110 {
    margin-right: 110px;
}

.mr-120 {
    margin-right: 120px;
}

.mr-130 {
    margin-right: 130px;
}

.mr-140 {
    margin-right: 140px;
}

.mr-150 {
    margin-right: 150px;
}

/*************************
      Margin bottom
*************************/

.mb-0 {
    margin-bottom: 0;
}
.mb-7{
    margin-bottom: 7px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}
.mb-25 {
    margin-bottom: 25px;
}
.mb-27 {
    margin-bottom: 27px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-95 {
    margin-bottom: 95px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-110 {
    margin-bottom: 110px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb-130 {
    margin-bottom: 130px;
}

.mb-140 {
    margin-bottom: 140px;
}

.mb-150 {
    margin-bottom: 150px;
}

/*************************
        Margin left
*************************/

.ml-0 {
    margin-left: 0;
}

.ml-10 {
    margin-left: 10px;
}

.ml-12 {
    margin-left: 12px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-55 {
    margin-left: 55px;
}

.ml-60 {
    margin-left: 60px;
}

.ml-70 {
    margin-left: 70px;
}
.ml-75 {
    margin-left: 75px;
}

.ml-80 {
    margin-left: 80px;
}

.ml-90 {
    margin-left: 90px;
}
.ml-95 {
    margin-left: 95px;
}

.ml-100 {
    margin-left: 100px;
}

.ml-110 {
    margin-left: 110px;
}

.ml-120 {
    margin-left: 120px;
}

.ml-130 {
    margin-left: 130px;
}

.ml-140 {
    margin-left: 140px;
}
.ml-145 {
    margin-left: 145px;
}

.ml-150 {
    margin-left: 150px;
}
.ml-ntv5 {
    margin-left: -5px;
}

/*************************
        Padding top
*************************/

.pt-0 {
    padding-top: 0;
}

.pt-5 {
    padding-top: 5px !important;
}
.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}
.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-55 {
    padding-top: 55px;
}

.pt-60 {
    padding-top: 60px;
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}
.pt-65 {
    padding-top: 65px;
}

.pt-70 {
    padding-top: 70px;
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}

.pt-75 {
    padding-top: 75px;
}

.pt-80 {
    padding-top: 80px;
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}

.pt-95 {
    padding-top: 95px;
}

.pt-90 {
    padding-top: 90px;
    @media #{$xs-layout} {
        padding-top: 40px;
    }
}

.pt-100 {
    padding-top: 100px;
    @media #{$xs-layout} {
        padding-top: 50px;
    }
}

.pt-105 {
    padding-top: 105px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-125 {
    padding-top: 125px;
}

.pt-128 {
    padding-top: 128px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-135 {
    padding-top: 135px;
}

.pt-140 {
    padding-top: 140px;
}

.pt-150 {
    padding-top: 150px;
}

.pt-155 {
    padding-top: 155px;
}
.pt-295 {
    padding-top: 295px;
    @media #{$xl-layout} {
        padding-top: 200px;
    }
    @media #{$lg-layout} {
        padding-top: 145px;
    }
    @media #{$md-layout} {
        padding-top: 95px;
    }
    @media #{$xs-layout} {
        padding-top: 47px;
    }
}

.pt-170 {
    padding-top: 170px;
    @media #{$md-layout} {
        padding-top: 100px;
    }
    @media #{$xs-layout} {
        padding-top: 70px;
    }
}

.pt-180 {
    padding-top: 180px;
}

.pt-205 {
    padding-top: 205px;
}
.pt-210 {
    padding-top: 210px;
    @media #{$lg-layout} {
        padding-top: 110px;
    }
    @media #{$md-layout} {
        padding-top: 110px;
    }
    @media #{$xs-layout} {
        padding-top: 60px;
    }
}

.pt-230 {
    padding-top: 230px;
}

.pt-270 {
    padding-top: 270px;
}

.pt-240 {
    padding-top: 240px;
}

.pt-365 {
    padding-top: 365px;
}

.pt-370 {
    padding-top: 370px;
}

.pt-380 {
    padding-top: 380px;
}

/*************************
        Padding right
*************************/

.pr-0 {
    padding-right: 0;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pr-40 {
    padding-right: 40px;
}

.pr-50 {
    padding-right: 50px;
}

.pr-60 {
    padding-right: 60px;
}

.pr-70 {
    padding-right: 70px;
}

.pr-80 {
    padding-right: 80px;
}

.pr-90 {
    padding-right: 90px;
}

.pr-100 {
    padding-right: 100px;
}

.pr-110 {
    padding-right: 110px;
}

.pr-120 {
    padding-right: 120px;
}

.pr-130 {
    padding-right: 130px;
}

.pr-140 {
    padding-right: 140px;
}

.pr-155 {
    padding-right: 155px;
}

.pr-270 {
    padding-right: 270px;
}

/*************************
        Padding bottom
*************************/

.pb-0 {
    padding-bottom: 0;
}

.pb-10 {
    padding-bottom: 10px;
}
.pb-12 {
    padding-bottom: 12px;
}
.pb-14 {
    padding-bottom: 14px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}
.pb-32 {
    padding-bottom: 32px;
}
.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}
.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}
.pb-55 {
    padding-bottom: 55px;
}

.pb-60 {
    padding-bottom: 60px;
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-65 {
    padding-bottom: 65px;
}

.pb-70 {
    padding-bottom: 70px;
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-75 {
    padding-bottom: 75px;
}

.pb-80 {
    padding-bottom: 80px;
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-85 {
    padding-bottom: 85px;
}

.pb-90 {
    padding-bottom: 90px;
    @media #{$xs-layout} {
        padding-bottom: 40px;
    }
}

.pb-95 {
    padding-bottom: 95px;
    @media #{$xs-layout} {
        padding-bottom: 45px;
    }
}

.pb-100 {
    padding-bottom: 100px;
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-105 {
    padding-bottom: 105px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pb-125 {
    padding-bottom: 125px;
}

.pb-130 {
    padding-bottom: 130px;
}

.pb-140 {
    padding-bottom: 140px;
}

.pb-145 {
    padding-bottom: 145px;
}

.pb-150 {
    padding-bottom: 150px;
}
.pb-165 {
    padding-bottom: 165px;
    @media #{$md-layout} {
        padding-bottom: 95px;
    }
    @media #{$xs-layout} {
        padding-bottom: 65px;
    }
}

.pb-170 {
    padding-bottom: 170px;
}

.pb-180 {
    padding-bottom: 180px;
}

.pb-280 {
    padding-bottom: 280px;
}
.pb-240 {
    padding-bottom: 240px;
    @media #{$xl-layout} {
        padding-bottom: 200px;
    }
    @media #{$lg-layout} {
        padding-bottom: 150px;
    }
    @media #{$md-layout} {
        padding-bottom: 100px;
    }
    @media #{$xs-layout} {
        padding-bottom: 50px;
    }
}

.pb-210 {
    padding-bottom: 210px;
    @media #{$lg-layout} {
        padding-bottom: 110px;
    }
    @media #{$md-layout} {
        padding-bottom: 110px;
    }
    @media #{$xs-layout} {
        padding-bottom: 60px;
    }
}

.pb-285 {
    padding-bottom: 285px;
}

.pb-270 {
    padding-bottom: 270px;
}

.pb-290 {
    padding-bottom: 290px;
}

/*************************
        Padding left
*************************/

.pl-0 {
    padding-left: 0;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pl-40 {
    padding-left: 40px;
}

.pl-50 {
    padding-left: 50px;
}

.pl-60 {
    padding-left: 60px;
}

.pl-70 {
    padding-left: 70px;
}

.pl-80 {
    padding-left: 80px;
}

.pl-90 {
    padding-left: 90px;
}

.pl-100 {
    padding-left: 100px;
}

.pl-110 {
    padding-left: 110px;
}

.pl-120 {
    padding-left: 120px;
}

.pl-130 {
    padding-left: 130px;
}

.pl-140 {
    padding-left: 140px;
}

.pl-150 {
    padding-left: 150px;
}

.pl-155 {
    padding-left: 155px;
}

.pl-190 {
    padding-left: 190px;
}

/***************************
    Page section padding 
****************************/

.ptb-0 {
    padding: 0;
}

.ptb-10 {
    padding: 10px 0;
}

.ptb-20 {
    padding: 20px 0;
}

.ptb-30 {
    padding: 30px 0;
}

.ptb-35 {
    padding: 35px 0;
}

.ptb-32 {
    padding: 32px 0;
}

.ptb-40 {
    padding: 40px 0;
}

.ptb-50 {
    padding: 50px 0;
}

.ptb-60 {
    padding: 60px 0;
}

.ptb-70 {
    padding: 70px 0;
}

.ptb-80 {
    padding: 80px 0;
}

.ptb-90 {
    padding: 90px 0;
}

.ptb-100 {
    padding: 100px 0;
}

.ptb-110 {
    padding: 110px 0;
}

.ptb-120 {
    padding: 120px 0;
}

.ptb-130 {
    padding: 130px 0;
}

.ptb-140 {
    padding: 140px 0;
}

.ptb-150 {
    padding: 150px 0;
}

.ptb-160 {
    padding: 160px 0;
}

.ptb-170 {
    padding: 170px 0;
}

.ptb-177 {
    padding: 177px 0;
}

.ptb-180 {
    padding: 180px 0;
}

.ptb-190 {
    padding: 190px 0;
}

.ptb-200 {
    padding: 200px 0;
}

.ptb-210 {
    padding: 210px 0;
}

.ptb-220 {
    padding: 220px 0;
}

.ptb-290 {
    padding: 290px 0;
}

.ptb-310 {
    padding: 310px 0;
}

/***************************
    Page section margin 
****************************/

.mtb-0 {
    margin: 0;
}

.mtb-10 {
    margin: 10px 0;
}

.mtb-15 {
    margin: 15px 0;
}

.mtb-20 {
    margin: 20px 0;
}

.mtb-30 {
    margin: 30px 0;
}

.mtb-40 {
    margin: 40px 0;
}

.mtb-50 {
    margin: 50px 0;
}

.mtb-60 {
    margin: 60px 0;
}

.mtb-70 {
    margin: 70px 0;
}

.mtb-80 {
    margin: 80px 0;
}

.mtb-90 {
    margin: 90px 0;
}

.mtb-100 {
    margin: 100px 0;
}

.mtb-110 {
    margin: 110px 0;
}

.mtb-120 {
    margin: 120px 0;
}

.mtb-130 {
    margin: 130px 0;
}

.mtb-140 {
    margin: 140px 0;
}

.mtb-150 {
    margin: 150px 0;
}

.mtb-290 {
    margin: 290px 0;
}
.heading {
    p,
    h3 {
        font-size: 20px;
        color: $grey90;
    }
    h3 {
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
        font-weight: 400;
    }
    h4{
        font-size: 20px;
        color: #373334;
        font-size: 14px;
        font-weight: 400;
    }
}
.css-1d1r5q-MuiFormHelperText-root.Mui-error{
    font-size: 0.75rem;
    font-weight: 500;
    color: #f44336;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}
.banner{
    @media #{$xx-layout}, #{$xl-layout}, #{$xl-layout} {
        display: none;
    }
    background-color: #FAEDAF;
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
    .icon{
        width: 40px;
        height: 40px;
        border-radius: 8px;
        padding-left: 10px;
        cursor: pointer;
    }
}
}
.delivey-form{
    .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-inputRoot, .MuiAutocomplete-inputRoot{
        border-color: $blue !important;
        &:hover{
            border-color: $blue !important;
        }
    }
    .MuiAutocomplete-input{
        height: 16px !important;
    }
    input{
        border:none !important;
        // border-radius: 20px;
        &:focus {
            border:none !important;
        }
        &::placeholder {
            color:$black;
            font-size: 16px;
        }
    }
    .contact-us{
        text-align: center;
        padding: 12px 45px;
        background-color: $bg-grey;
        border-radius: 6px;
        span{
            color: #71DBD4;
            text-decoration: underline;
             
        }
        @media #{$xs-layout} {
            padding: 12px ;
            font-size: 13px;
        }
    }
    .button-send{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        width: 80%;
        margin: 0px auto;
        @media #{$xs-layout} {
            width: 100%;
        }
        .btn{
            max-width: 60%;
            background-color: $bg-black;
            color: $white !important;
            @media #{$xs-layout} {
                max-width: 100%;
            }  
        }
        .disabled {
            background-color: $bg-grey1 !important;
        }
        .active {
            background-color:#000000 !important;
            cursor: pointer !important;
        }
    }
    
}
[dir='rtl'] .MuiAutocomplete-endAdornment{
    right: 0 !important;
    left: 9px !important;
    position: inherit;
}
[dir='rtl'] .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding-right: 10px !important;
}
[dir='rtl'] .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
    padding-right: 9px !important;
    padding-left: 9px !important;
}
[dir='rtl'] .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding-right: 14px !important;
    padding-left: 14px !important;
}
[dir='rtl'] .MuiSelect-icon {
    left: 9px;
}
[dir='rtl'] .MuiAutocomplete-hasPopupIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding-left: 20px !important;
    padding-right: 10px !important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,.css-segi59 {
    border-radius: 12px !important;
   
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 16px !important;
    font: inherit !important;
}
a:not([href]):not([class]){
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
        color: #0d6efd;
        text-decoration: underline;
        cursor: pointer;
    }
}
.css-1bp1ao6{
    border-radius: 12px !important;
}