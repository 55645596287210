.toolbar-padding{
    padding-top: 44px;
    @media #{$xs-layout}{
        padding-top: 28px;
    }

}
.toolbar{  
    height: 48px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: $bg-white;
    opacity: 0.95;
    border-radius: 40px;
    padding: 4px 16px 4px 16px;
    z-index: 99999;
    .navigation {
        display: flex;
        height: 100%;
        align-items: center;
        .logo {
            margin-right: 5px;
            width: 140px;
            align-items: center;
            @media #{$xs-layout}{
                width: 100px   
            }
            @media screen and (max-width: 360px) {
                width: 66px
                }
        }
        .cartona{
            height: 30px; 
            width: 2px;
            background-color: gray;
            margin-right: 7px; 
            margin-left: 7px; 
                @media #{$xs-layout}{
                    height: 20px;
                    width: 1.5px;
                }
                @media screen and (max-width: 360px) {
                    margin: 0;
                    }
        }
       .langImg{
            @media screen and (max-width: 360px) {
                width: 30px
                }
        }
    }
    .spacer {
        flex: 1;
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                background-color: $bg-aqua;
                border-radius: 100px;
                padding-left: 10%;
                padding-right: 10%;
                @media #{$small-mobile}{
                    padding-left: 3%;
                    padding-right: 3%;
                    padding-top: 3%;
                    padding-bottom: 3%;
                }
            }
            p{
                text-align: center;
                font-weight: 700;
                margin: 0;
                font-size: 20px;
                @media #{$xs-layout}{
                    font-size: 16px  
                }
                @media #{$small-mobile}{
                    font-size: 12px;
                }
                
            } 
            span{
                color: $blue;
                font-weight: bold;
            }
        }
        
    }
    .navigation-items {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
        }
        li {
            margin: 0 30px 0 0;
            cursor: pointer;
            @media #{$extra-small-mobile} {
                margin: 0 10px 0 0;
            }
            &:last-child {
                margin: 0px;
            }
        }
    }
}
[dir='rtl'] .navigation-items {
    li {
        margin: 0 0 0 30px;
        @media #{$xs-layout}{
            margin: 0 0 0 10px;
        }
    }
}
[dir='rtl'] .toolbar .navigation .logo {
    margin-right: 0px;
    margin-left: 5px;
}
