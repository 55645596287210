.btn {
    border-radius: 12px;
    border: none;
    text-transform: capitalize;
    width: 100%;
    height: 52px;
    font-size: 16px;
    font-weight: 700;
    opacity: 1;
    &:focus {
        box-shadow: none;
    }
}
.disabled {
    color: $grey !important;
    background-color: $bg-grey1 !important;
}
.active {
    color: $grey90 !important;
    background-color: rgb(38, 247, 253) !important;
    cursor: pointer !important;
}
.insurance-type-card {
    width: 200px;
    min-width: 167px;
    height: 160px;
    border-radius: 12px;
    background-color: $bg-grey10;
    margin: 20px 0 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    @media #{$xs-layout} {
        height: 77px;
        min-width: 100%;
        align-items: center;
    }
    @media #{$md-layout} {
        min-width: 100%;
    }
    input {
        width: auto;
        float: right;
        height: auto;
        margin-top: 5px;
        position: relative;
        margin-left: 10px;
    }

    input[type='radio']:after {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: $bg-grey1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $bg-grey1;
    }
    input[type='radio']:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        top: 0px;
        left: 0px;
        position: absolute;
        background-color: $bg-blue;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $bg-blue;
    }
    label {
        font-size: 15px;
        font-weight: bold;
        color: $grey90;
        width: 100%;
        @media #{$xs-layout} {
            font-size: 12px;
        }
    }
    .insuranceTypeIcon{ 
        align-items: center;
        display: flex ;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        @media #{$xs-layout} {
            align-items: center;
            display: flex ;
            flex-direction: row;
        }
        .image{
            @media #{$md-layout} {
                margin-right: 17px;
            }
            @media #{$xs-layout} {
                margin-right: 17px;
            }
        }
        img{
            @media #{$xs-layout} {
                width: 20px;
                height: 20px;
            }
        }
    }
    .fionkaImg{
    right: -4px;
    height: 37px;
    position: absolute;
    bottom: -3px;
    }
    .childStyle{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        span{
          font-size: 24px;
          font-weight: 700;
          color: $blue;
          @media #{$xs-layout} {
            font-size: 12px;
        }
        }
    }
}
[dir='rtl'] .fionkaImg{
    right: auto !important;
    left: -4px;
    bottom: -3px;
    height: 37px;
    position: absolute;
    transform: scaleX(-1.1)
}
[dir='rtl'] .image {
     margin-left: 17px;
     margin-right: 0px !important;
}
[dir='rtl'] input[type='radio'] {
    float: left;
    margin-left: 0px;
    margin-right: 10px;
}
.selected {
    border: 1px solid $blue;
    label {
        color: $blue;
    }
}
.stepper {
    width: 57px;
    height: 4px;
    margin: 5px;
    background-color: $bg-grey1;
}
.active {
    background-color: rgb(38, 247, 253);
}
.custom-radio {
    margin: 24px 0 0;
    input[type='radio'] {
        opacity: 0;
        position: fixed;
        width: 0;
    }
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bg-grey10;
        padding: 12px 24px;
        font-size: 16px;
        color: $grey80;
        border-radius: 12px;
        height: 56px;
        width: 100%;
        text-align: center;
        &:hover {
            background-color: $bg-grey10;
        }
    }
}
.custom-radio input[type='radio']:checked + label {
    background-color: $bg-white;
    border: 1px solid $blue;
    color: $black;
    font-weight: 700;
}
.loading-packages {
    justify-content: center;
    align-items: center;
    padding: 54px 15px 0 15px;
    display: flex;
    flex-direction: column;
    @media #{$xs-layout}{
        padding-top: 101px;
    }

    .user-img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-right: 12px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .heading {
        font-size: 20px;
        font-weight: bold;
        color: $grey90;
        line-height: 28px;
    }
    .subHeading {
        font-size: 16px;
        color: $grey80;
        line-height: 24px;
        margin-bottom: 4px;
        margin-top: 24px;
    }
}
[dir='rtl'] .loading-packages .user-img{
    margin-right: 0px;
    margin-left: 12px;
}

.pricing-card {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 15px;
    @media #{$xs-layout} {
        overflow-x: auto;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .parent-card{
        @media #{$xs-layout} {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .card {
        margin-top: 20px;
        background: $bg-grey10;
        min-width: 216px;
        height: auto;
        text-align: inherit;
        border-radius: 12px;
        transition: 0.5s ease-in-out;
        position: relative;
        padding: 20px;
        @media #{$xs-layout}{
            min-height: auto;
            margin-top: 15px;
        }
        &:hover {
            // transform: scale(1.1);
            box-shadow:inset 0 0 5px #0f3eca;
        }
        .title {
            h2 {
                font-size: 20px;
                font-weight: 700;
                color: $blue;
                text-transform: capitalize;
            }
        }
        .price {
            margin-bottom: 16px;
            h5 {
                font-size: 22px;
                font-weight: bold;
                color: $black;
                margin-bottom: 0px;
                span {
                    font-size: 16px;
                    color: $black;
                    opacity: 0.5;
                }
            }
            span {
                font-size: 14px;
                color: $black;
                opacity: 0.5;
            }
        }
        .options {
            margin-bottom: 5px;
            @media #{$xs-layout}{
                margin-bottom: 0px;
            }
            li {
                list-style: none;
                margin-bottom: 12px;

                h3,
                p {
                    font-size: 14px;
                    color: $black;
                    margin-bottom: 3px;
                    line-height: 20px;
                    white-space: nowrap;
                    img{
                        cursor: pointer;
                    }
                }
                p {
                    font-weight: bold;
                }
                .more{
                    font-size: 14px;
                    margin-bottom: 0px;
                    color: $blue;
                    cursor: pointer;
                  
                }
                &:last-child {
                    text-align: right;
                    margin-top: 16px;
                }
                [dir='rtl']  &:last-child {
                    text-align: left;
                    margin-top: 16px;
                }
            }
        }
    }

    .btn {
        height: 52px;
        width: 90%;
        border-radius: 0px 0px 8px 8px;
        background-color: $bg-blue;
        color: white;
        border: none;
        font-size: 16px;
        font-weight: 700;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        margin: 0px auto;
        border-radius: 8px;
        @media #{$xs-layout},#{$md-layout},#{$lg-layout}{
            height: 60px;
        }
    }
}
:global(.MuiPopover-paper) {
    max-height: 100px;
}
:global(.MuiPopover-paper) {
    max-height: 100px;
}
.MuiPopover-paper {
    max-height: 100px;
}
.ui-select {
    :global(.MuiPopover-paper) {
        max-height: 100px;
    }
    :global(.MuiList-root) {
        max-height: 100px;
    }
    .form-control {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0px;
        border-color: $grey-light;
        color: $grey80;
        background: url('../../assets/img/arrow_down.svg') white no-repeat 98.5% !important;
        option {
            color: $grey80;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .MuiList-root {
        max-height: 380px;
    }
    .MuiList-root .MuiMenu-list .MuiList-padding{
        display: flex;
        padding: 0px 24px;

    }

    select:required:invalid {
        color: gray;
    }
    option[value=''][disabled] {
        display: none;
    }
}
.MuiMenu-list{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
 .MuiList-padding{
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.MuiMenuItem-root{
    height: 56px !important;
    min-width: 32% !important;
    justify-content: center !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $grey80 !important;
    font-family: 'Circular' !important;
    letter-spacing: 0.09em !important;
    @media #{$xs-layout}{
        min-width: 46% !important;
        letter-spacing: 0.03em !important;
    }
    @media #{$extra-small-mobile}{
        padding-left: 6px !important;
        padding-right: 6px !important;
        min-width: 40%;
    }

}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected{
    background-color: transparent !important;
    color: $blue !important;
    border-radius: 12px;
    border: 4px solid rgba(15, 62, 202, 1);
    font-weight: 700 !important;
    @media #{$xs-layout}{
        border:2px solid rgba(15, 62, 202, 1);
    }
}
[dir='rtl'] .MuiMenuItem-root{

    font-family: 'Almarai' !important;
    font-weight: 700;
}
.MuiAutocomplete-listbox{
    padding: 24px 8px !important;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
.MuiAutocomplete-option{
    height: 56px !important;
    min-width: 32% !important;
    justify-content: center !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $grey80 !important;
    font-family: 'Circular' !important;
    letter-spacing: 0.09em !important;
    @media #{$xs-layout}{
        min-width: 46% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        letter-spacing: 0.03em !important;
    }

}
[dir='rtl'] .MuiAutocomplete-option{

    font-family: 'Almarai' !important;
    font-weight: 700;
}
.MuiAutocomplete-option[aria-selected="true"],.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: transparent !important;
    color: $blue !important;
    border-radius: 12px;
    border: 4px solid rgba(15, 62, 202, 1);
    font-weight: 700 !important;
    @media #{$xs-layout}{
        border:2px solid rgba(15, 62, 202, 1);
    }
}
[dir='rtl'] .ui-select .MuiSelect-select.MuiSelect-select {
    padding-right: 9px;
    padding-left: 9px;
}
[dir='rtl']
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot {
    padding-right: 0px !important;
    padding-left: 56px !important;
}

.checkout-card {
    .card-btn {
        min-width: 161.5px;
        height: 56px;
        background-color: $bg-grey10;
        border: none;
        border-radius: 8px;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        text-align: left;
        padding-left: 12px;

        @media #{$extra-small-mobile}{
            min-width: 133.5px;
        }
        img {
            margin-right: 8px;
        }
        .vf-cash{
            width: 90%;
            height: 80%;
            object-fit: contain;
        }
    }
    .installments-plans{
        height: 52px;
    }
    .small-card {
        width: 103.67px;
        padding-left: 12px;
        text-transform: uppercase;
        img {
            margin-right: 8px;
        }
    }
    .selected-btn {
        border: 2px solid $blue;
        background-color: $bg-white;
        color: $blue;
        font-weight: 700;
    }
}
[dir='rtl'] .checkout-card .card-btn {
    text-align: right;
    padding-left: 0px;
    padding-right: 14px;
    img {
        margin-right: 0px;
        margin-left: 10px;
    }
}
[dir='rtl'] .checkout-card .small-card {
    padding-left: 0px;
    padding-right: 12px;
    img {
        margin-right: 0px;
        margin-left: 8px;
    }
}

.policy-summary-card {
    min-height: 218px;
    min-width: 335px;
    max-width: 365px;
    padding: 20px;
    border-radius: 8px;
    background-color: $bg-blue;
    @media #{$desktop-layout}{
        min-height: 242px;
    }
    @media #{$extra-small-mobile} {
        min-width: auto;
    }
    p {
        color: $white;
        @media #{$xs-layout} {
            letter-spacing: 0.8px;
        }
        span {
            color: $aquamarine;
        }
    }
    .policy-date {
        font-size: 16px;
        line-height: 24px;
        @media #{$xs-layout} {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .policy-name{
        font-size: 20px;
        line-height: 28px;
        @media #{$xs-layout} {
            font-size: 16px;
            line-height: 24px;
        }
    }

    button {
        margin-top: 20px;
        height: 47px;
        line-height: 20px;
        width: 100%;
        border-radius: 4px;
        background-color: $bg-dark-blue;
        color: $blue;
        font-size: 16px;
        font-weight: bold;
        border: none;
        text-transform: none;
    }
    .active-btn {
        background-color: $bg-white;
        color: $black;
    }
}


.installments {
    padding: 20px;
    border-radius: 8px;
    background-color: $bg_aquamarine;
    p {
        color: $grey90;
        @media #{$xs-layout} {
            letter-spacing: 0.8px;
        }
        span {
            color: $blue;
        }
    }
    .dark-black {
        color: $black;
    }
    .policy-date {
        font-size: 16px;
        line-height: 24px;
        @media #{$xs-layout} {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .policy-name{
        font-size: 20px;
        line-height: 28px;
        @media #{$xs-layout} {
            font-size: 16px;
            line-height: 24px;
        }
    }
    button {
        margin-top: 20px;
        height: 47px;
        line-height: 20px;
        width: 100%;
        border-radius: 4px;
        background-color: #48c6bd;
        color: $aquamarine;
        font-size: 16px;
        font-weight: bold;
        border: none;
        text-transform: none;
    }
    .active-btn {
        background-color: $bg-blue;
        color: $white;
    }
}
.purchase-link-web{
    min-height: auto;
    position: static;
}
.btn-purchase {
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    
    line-height: 28px;
    border-radius: 4px;
    border: none;
    width: 100%;
}
.upload-now {
    background-color: $bg-blue;
    color: $white;
}
.upload-later {
    background-color: transparent;
    color: $blue;
}
.try-again {
    background-color: $bg-red;
    color: $white;
}
.parteners-section{
    margin-top: 60px;
    margin-bottom: 30px;
    h3{
        color: $aquamarine;
		font-size: 20px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 12px;
    }
    p{
        color: $black;
		font-size:24px;
		font-weight: 700;
		text-align: center;
    }
    .space{
        justify-content: space-evenly;
    }
    .cards{
        display: flex;
		flex-direction: row;
		padding-top: 0px;
		flex-wrap: wrap;
        // gap:3.3%;
        justify-content: center;
        .card{   
		width: 27%;
		height: 92px;
		align-items: center;
		justify-content: center;
		border-radius: 17px;
		background-color: $bg-white;
		margin:10px 0px;
        border: none;
        @media #{$xs-layout}{
            width: 28%;
            margin: 10px 1.5%;
        }
        img{
            width: 80%;
            height: 80%;
            object-fit: contain;
        }

        }
    }
}
::-webkit-scrollbar {
    width: 8px;
    left: -100px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #0064b1;
    border-radius: 10px;
    background: #0391fd33;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0064b1;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0064b1;
}