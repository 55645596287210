div#container
{
   min-height: calc(100vh - 203px);
}

#header a
{
   color: $yellow;
   text-decoration: underline;
}
#header a:visited
{
   color: $black;;
}
#header a:active
{
   color: #FF0000;
}
#header a:hover
{
   color: $yellow;
   text-decoration: underline;
}
#header input:focus, #header textarea:focus, #header select:focus
{
   outline: none;
}

#header
{
   background-color: $bg-off_white;
   background-image: none;
   box-sizing: border-box;
}
#wb_text 
{
   background-color: transparent;
   background-image: none;
   border: 0px solid #000000;
   padding: 0;
   margin: 0;
   text-align: center;
}
#wb_text div
{
   text-align: center;
}
#wb_text span{
	color:$off-white;
   font-size:13px;
}
#wb_copyright 
{
   background-color: transparent;
   background-image: none;
   border: 0px solid #000000;
   padding: 0;
   margin: 0;
   text-align: center;
}
#wb_copyright div
{
   text-align: center;
}
#wb_copyright span{
	color:#FFFFFF;
	font-size:12px;
}
#wb_logo
{
   vertical-align: top;
}
#logo
{
   border: 0px solid #000000;
   padding: 0;
   margin: 0;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
}
#wb_menu
{
   border: 0px solid #C0C0C0;
   background-color: transparent;
   margin: 0;
   text-align: left;
}
#wb_menu ul
{
   list-style-type: none;
   margin: 0;
   padding: 0;
   position: relative;
   display: inline-block;
}
#wb_menu li
{
   float: left;
   margin: 0;
   padding: 0px 20px 0px 0px;
}
#wb_menu a
{
   display: block;
   float: left;
   color: $primary;
   border: 0px solid #CCCCCC;
   background-color: transparent;
   background-image: none;
   font-weight: bold;
   font-size: 16px;
   font-style: normal;
   text-decoration: none;
   height: 28px;
   line-height: 28px;
   padding: 0px 5px 0px 5px;
   text-align: center;
}
#wb_menu li:hover a, #wb_menu a:hover
{
   color: $black;
   border: 0px solid $yellow;
   background-color: transparent;
   background-image: none;
}
#wb_menu li.firstmain
{
   padding-left: 0px;
}
#wb_menu li.lastmain
{
   padding-right: 0px;
}
#wb_menu br
{
   clear: both;
   font-size: 1px;
   height: 0;
   line-height: 0;
}
#footer
{
   background-color: $bg-grey;
   background-color: transparent;
   background-image: none;
   box-sizing: border-box;
}
#wb_copyright
{
   position: absolute;
   left: 82px;
   top: 49px;
   width: 806px;
   height: 22px;
   text-align: center;
   z-index: 3;
   span {
      color: $black;
   }
}
#footer_Container
{
   width: 1000px;
   position: relative;
   margin-left: auto;
   margin-right: auto;
   text-align: left;
}
#wb_logo
{
   position: absolute;
   left: 729px;
   top: 27px;
   width: 250px;
   height: 61px;
   z-index: 0;
}
#wb_text
{
   position: absolute;
   left: 82px;
   top: 21px;
   width: 806px;
   height: 25px;
   text-align: center;
   z-index: 2;
   a{
      color: $aquamarine;
   }
   span {
      color: $black;
   }
}
#footer
{
   text-align: center;
   width: 100%;
   height: 88px;
}
#header_Container
{
   width: 1000px;
   position: relative;
   margin-left: auto;
   margin-right: auto;
   text-align: left;
}
#wb_menu
{
   position: absolute;
   left: 25px;
   top: 43px;
   width: 100%;
   height: 30px;
   z-index: 1;
}
#header
{
   text-align: center;
   width: 100%;
   height: 115px;
 
}
@media only screen and (max-width: 999px)
{
	div#container
	{
		width: 100%;
	}
	#header
	{
		top: 0px;
		height: 164px;
		visibility: visible;
	}
	#header
	{
		width: 100%;
	}
	#header_Container
	{
		width: 100%;
	}
	#wb_text
	{
      position: relative;
		left: 0;
      width: 100%;
		height: auto;
		visibility: visible;
      display: block;
      top: 0;
      
	}
	#wb_text
	{
		margin: 0;
		padding: 0;
	}
	#wb_copyright
	{
		position: relative;
		left: 0;
		width: 100%;
		height: auto;
		visibility: visible;
      display: block;
      top: 0;

   }
	#wb_copyright
	{
		margin: 0;
		padding: 0;
	}
	#wb_logo
	{
      position: relative;
		left: 0;
		top: 0;
		width: auto;
		height: 61px;
		visibility: visible;
		display: inline;
		margin: 0;
      padding: 0;
      max-width: 100%;
      #logo{
         max-width: 200px;
      }
   }
   #header_Container{
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px
   }
	#wb_menu
	{
      position: relative;
		left: 0;
		top: 10;
		visibility: visible;
		display: block;
		margin: 0;
		text-align: center;
	}
   #wb_menu ul{
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      justify-content: space-between;
      flex-direction: row-reverse;
   }
	#wb_menu a
	{
		color: $primary;
		border: 0px solid #CCCCCC;
		background-color: transparent;
		background-image: none;
	
		font-weight: bold;
		font-size: 16px;
		font-style: normal;
		text-decoration: none;
		line-height: 28px;
		text-align: center;
	}
	#wb_menu li:hover a, #wb_menu a:hover
	{
		color: $primary;
		border: 0px solid $yellow;
		background-color: transparent;
		background-image: none;
	}

	#footer_Container
	{
		width: 100%;
	}
}